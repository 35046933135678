<template>
    <div>

        <v-layout wrap justify-center>
            <v-flex lg11>



                <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 lg12 px-5 pt-10>
                        <span style="font-family: Montserrat-Bold; font-size: 28px;">Settings</span>
                    </v-flex>

                    <!-- <v-flex xs12 sm3 md3 lg3 px-5 pt-2 pt-sm-10 class="text-sm-end">
                        <v-btn style="background-color: #00A08E;" @click="editdialog = true">
                            <span style="font-family: Lato-Regular; font-size: 16px; color: white;">Edit</span>
                        </v-btn>
                    </v-flex> -->

                    <!-- <v-flex xs12 sm12 md12 lg12 px-5 pt-10 class="text-sm-end">
                <v-btn style="background-color: #00A08E;" @click="adddialog = true">
              <span style="font-family: Lato-Regular; font-size: 16px; color: white;">Add</span>
            </v-btn>
            </v-flex> -->

                </v-layout>

                <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 lg12 pt-5 px-6>
                        <v-card>
                            <v-layout wrap justify-center pt-4 px-4>
                                <v-flex xs7 sm10 md10 lg10>

                                    <span>Tax Percentage</span>

                                </v-flex>
                                <v-flex xs5 sm2 md2 lg2 class="text-end">
                                    <v-btn small style="background-color: #00A08E;" @click="addTaxPercentageDial()">
                                    <span style="color: white;">Update</span>
                                    </v-btn>

                                </v-flex>
                            </v-layout>

                            <v-layout wrap justify-center pa-4 pt-2>
                                <v-flex xs12 sm12 md12 lg12>
                                    <v-card outlined style="border-width: 2px;">
                                        <v-layout wrap justify-center>
                                            <v-flex xs12 sm12 md12 lg12 pa-2>
                                                <span>INR Tax Percentage : {{ taxPercentage }} %</span>
                                            </v-flex>
                                        </v-layout>
                                </v-card>
                                </v-flex>
                            </v-layout>

                        </v-card>
                    </v-flex>
                </v-layout>

                <v-dialog
                v-model="addTaxPercentageDialog"
                width="400px"
                content-class="dialog-custom"
                >
                <v-card>
                    <v-layout wrap justify-center pa-8 pt-6>
                    <v-flex xs12 sm12 md12 lg12 xl12>
                        <v-layout wrap justify-center>
                        <v-flex xs11 sm11 md11 lg11 pb-3>
                            <span
                            style="
                                font-family: Montserrat-Regular;
                                font-size: 24px;
                                font-weight: 700;
                            "
                            >Add Tax Percentage
                            </span>
                        </v-flex>
                        <v-flex xs1 sm1 md1 lg1>
                            <v-btn icon>
                            <v-icon @click="addTaxPercentageDialog = false">mdi-close</v-icon>
                            </v-btn>
                        </v-flex>

                        <v-flex xs12 sm12 md12 lg12>
                            <span
                            style="
                                font-family: Lato-Regular;
                                font-size: 16px;
                                font-weight: 500;
                            "
                            >
                            INR Tax Percentage (%)
                            </span>
                        </v-flex>
                        <v-flex  xs12 sm12 md12 lg12 pt-1>
                            <v-text-field
                            outlined
                            dense
                            type="number"
                            placeholder="Tax Percentage"
                            v-model="inrTaxPercentage"
                            hide-details="auto"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
                            <v-btn color="rgba(0, 160, 142, 1)" @click="addTaxPercentage()">
                            <span
                                style="
                                color: white;
                                font-family: Montserrat-Regular;
                                font-size: 14px;
                                font-weight: 600;
                                text-transform: none;
                                "
                            >
                                Submit
                            </span>
                            </v-btn>
                        </v-flex>
                        </v-layout>
                    </v-flex>
                    </v-layout>
                </v-card>
                </v-dialog>

            </v-flex>
        </v-layout>
    </div>
</template>
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      addTaxPercentageDialog: false,
      inrTaxPercentage: "",
      taxPercentage: "",
      expandOnHover: false,
      editdialog: false,
      aboutlist: {
        content: "", // Ensuring this is reactive
      },
      currentPage: 1,
      userType: null, // Assuming this is needed based on the watcher
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    userType() {
      this.getData();
      this.currentPage = 1;
    },
  },
  mounted() {
    this.getTaxPercentage();
  },
  methods: {
    addTaxPercentageDial() {
    this.inrTaxPercentage = this.taxPercentage; // Auto-fill with the current value
    this.addTaxPercentageDialog = true;
},

    addTaxPercentage() {
    axios({
      method: "POST",
      url: "/settings/inr-tax/add-update",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        inrTaxPercentage: this.inrTaxPercentage,
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.getTaxPercentage(); 
          this.addTaxPercentageDialog = false;
        } else {
          this.msg = response.data.msg;
          this.showsnackbar = true;
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },

  getTaxPercentage() {
    axios({
      url: "/settings/inr-tax/view",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.data.status) {
          this.taxPercentage = response.data.data.inrTaxPercentage;
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },
  },
};
</script>

  